import React from "react";
import avatar from "../assets/av.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode, faStar } from "@fortawesome/free-solid-svg-icons";
import Navigation from '../components/Navigation';

import { Row, Col, Image } from "react-bootstrap";

const Home = () => {
  return (
    <div className="Main">
    <Navigation />
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col>
          <Image
            src={avatar}
            className="avatar"
            responsive="true"
            fluid
            roundedCircle
          />
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col>
          <h1>BRETT BARTHOLOMEW</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="hr-container">
            <div className="hr-line"></div>
            <div className="hr-icon">
              <FontAwesomeIcon icon={faLaptopCode} />
            </div>
            <div className="hr-line"></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>design&nbsp;<FontAwesomeIcon icon={faStar}/>&nbsp;code&nbsp;<FontAwesomeIcon icon={faStar}/>&nbsp;build</h4>
        </Col>
      </Row>
      </div>  
      );
};
export default Home;
